<template>
  <organization-user-form
    :entity="entity"
    :submit="addEntity"
  >
    <b-col md="6">
      <text-input
        id="business-email"
        name="Business Email"
        rules="required|email"
        :text.sync="entity.business_email"
      />
    </b-col>
  </organization-user-form>
</template>

<script>
import UserEntity from '@/common/compositions/UserEntity/userEntityAPI'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import OrganizationUserForm from '@/common/components/UserEntities/OrganizationUserForm.vue'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'

export default {
  name: 'IndividualUserSignUp',
  components: { OrganizationUserForm, TextInput },
  data() {
    return {
      tabIndex: 0,
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { entity, storeReclaimRequest } = UserEntity()
    return {
      entity, storeReclaimRequest, successfulOperationAlert,
    }
  },
  methods: {
    addEntity() {
      const claimedResource = JSON.parse(localStorage.getItem('claimedResource'))
      this.$set(this.entity, 'reclaim_type', claimedResource.reclaim_type)
      this.$set(this.entity, 'reclaim_id', claimedResource.reclaim_id)
      return this.storeReclaimRequest().then(() => {
        this.successfulOperationAlert('Entity is registered successfully')
        this.$router.push({
          name: 'login',
        })
      })
    },
  },
}
</script>

<style>

</style>

IndividualUserForm
